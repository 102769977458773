<template>
  <div>
    <div class="container" style="max-width: 1490px !important">
      <!-- header -->
      <BaseHeader
        title="Gestão de planos"
        :navigation="navigation"
      ></BaseHeader>

      <div class="box-total">
        <div class="total-vendas info">
          <b-form-checkbox
            v-model="status"
            @change="changeStatusForm"
            name="is_main"
            size="lg"
            switch
          >
            <p class="info-checkbox" style="width: 0px">
              {{ status ? " Ativos " : "Inativos" }}
            </p>
          </b-form-checkbox>
        </div>
        <div>
          <p class="total-vendas">
            Total de planos:
            <span>{{ total }}</span>
          </p>
        </div>
        <div></div>
      </div>
      <div class="header-table" style="justify-content: end; gap: 8px">
        <div class="inputSearch" style="position: relative">
          <img src="@/assets/img/icons/search.svg" class="Pointer search" />
          <input
            type="text"
            placeholder="Pesquisar Planos"
            v-model="search"
            @input="debounce"
            class="input-busca"
          />
        </div>
        <BaseButton
          variant="primary"
          @click="typeValidSimulator"
          v-if="!loading"
        >
          Criar Plano
        </BaseButton>
        <b-skeleton
          v-else
          width="173px"
          height="50px"
          type="button"
          style="border-radius: 10px"
        ></b-skeleton>
      </div>
      <!-- LISTAGEM DE SIMULAÇÔES-->
      <div v-if="all_simulator && !isMobile" class="zoom">
        <!-- HEADER -->
        <b-row
          class="Table-header mb-1 screenn"
          v-if="!isMobile"
          style="padding-left: 0 !important"
        >
          <!--NOME DO PLANO -->
          <b-col cols="2" class="d-none d-md-block" style="padding-left: 60px">
            Dados
          </b-col>
          <!--INVESTIMENTO -->
          <b-col cols="2" class="d-none d-md-block">Investimento</b-col>
          <!--CPL  -->
          <b-col cols="2" class="d-none d-md-block">CPL</b-col>
          <!--Total de vendas -->
          <b-col cols="2" class="d-none d-md-block" style="padding-left: 10px"
            >Total de vendas</b-col
          >
          <!--CPV -->
          <b-col
            cols="2"
            class="d-none d-md-block"
            style="padding-left: 10px; padding-right: 230px"
            >CPV</b-col
          >
          <!--Leads -->
          <b-col cols="1" class="d-none d-md-block" style="padding-left: 5px"
            >Leads</b-col
          >
          <!--STATUS -->
          <!-- <b-col cols="1" class="d-none d-md-block" style="padding-left: 5px"
            >Status</b-col
          > -->
          <!--ACOES -->
          <b-col cols="1" class="d-none d-md-block">Ações</b-col>
        </b-row>
        <template v-if="!loading">
          <div
            v-for="(simulation, index) in all_simulator"
            :key="index"
            class="Table-body-container"
          >
            <!-- BODY -->
            <template v-if="!isMobile">
              <b-row
                class="Table-body"
                id="border-bottom"
                style="padding-left: 0px !important"
              >
                <!--NOME DO PLANO -->
                <b-col
                  cols="2"
                  style="display: flex; align-items: center; gap: 20px"
                >
                  <img
                    v-b-tooltip="{
                      title: filterStatus(simulation.plan),
                      placement: 'top',
                    }"
                    v-if="filterStatus(simulation.plan) === 'Inativo'"
                    src="@/assets/icons/simulator/date_invalid.svg"
                    alt="date_invalid"
                  />
                  <img
                    v-b-tooltip="{
                      title: filterStatus(simulation.plan),
                      placement: 'top',
                    }"
                    v-if="filterStatus(simulation.plan) === 'Ativo'"
                    src="@/assets/icons/simulator/date_valid.svg"
                    alt="date_valid"
                  />

                  <span
                    style="word-break: break-all"
                    class="name-plan"
                    v-b-tooltip="{
                      title: simulation.plan.title,
                      placement: 'top',
                    }"
                  >
                    {{
                      simulation.plan.title.length > 12
                        ? simulation.plan.title.substr(0, 12) + "..."
                        : simulation.plan.title
                    }}<br />
                    <span style="font-size: smaller; color: var(--white-dark)"
                      >Início:
                      {{
                        simulation.plan.start_date.substr(0, 10) | date
                      }}</span
                    ><br />
                    <span style="font-size: smaller; color: var(--white-dark)"
                      >Final:
                      {{ simulation.plan.end_date.substr(0, 10) | date }}</span
                    >
                  </span>
                </b-col>
                <!-- INVESTIMENTO -->
                <b-col cols="2" class="container-investment-plan">
                  <div class="investment-total">
                    <img
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse.svg"
                      alt="ellipse-icon"
                    />
                    <div class="investment-value">
                      <div class="investment-value">
                        R$ {{ filterInvestment(simulation.plan) | formatMoney }}
                      </div>
                      <div style="font-weight: 300; font-size: 11px;">
                        ({{
                          filterInvestmentPercetentage(simulation) === "NaN"
                            ? 0
                            : filterInvestmentPercetentage(simulation)
                        }}%)
                      </div>
                    </div>
                    <!-- <span class="investment-percentage">(+50%)</span> -->
                  </div>
                  <div class="investment-total2">
                    <img
                      v-if="
                        filterInvestment(simulation.plan) > simulation.investing
                      "
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse-danger.svg"
                      alt="ellipse-icon"
                      :style="simulation.investing == 0 ? 'filter: saturate(0) brightness(1.8);' : ''"
                    />
                    <img
                      v-else
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse-sucess.svg"
                      alt="ellipse-icon"
                    />
                    <h2 v-if="simulation.investing > 0" class="investment-value">
                      R$ {{ simulation.investing | formatMoney }}
                    </h2>
                    <h2 v-else style="margin-top: -3px;">
                      <router-link 
                      style="font-size: 15px; text-decoration: none; color: var(--gray01); font-weight: 600;" 
                      :to="{ path: '/'+ $store.getters.user.user.tenant_subdomain +'/estrategico/trafego/lista/?ModalTraffic=' + simulation.plan.id, component: 'ModalCreateTraffic'}"
                       >Criar investimento 
                    </router-link>
                    </h2>

                    <img
                      v-if="
                        filterInvestment(simulation.plan) > simulation.investing && simulation.investing != 0
                      "
                      class="ellipse"
                      src="@/assets/icons/simulator/arrow-danger.svg"
                      alt="ellipse-icon"
                    />
                    <img
                      v-else-if="simulation.investing != 0"
                      class="ellipse"
                      src="@/assets/icons/simulator/arrow-sucess.svg"
                      alt="ellipse-icon"
                    />
                  </div>
                </b-col>
                <!-- CPL -->
                <b-col cols="2" class="container-investment-plan">
                  <div class="investment-total">
                    <img
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse.svg"
                      alt="ellipse-icon"
                    />

                    <div class="investment-value">
                      <div class="investment-value">
                        R${{ filtercpl(simulation.plan) | formatMoney }}
                      </div>
                      <div style="font-weight: 300; font-size: 11px;">
                        ({{
                          filtercplPercentage(simulation) === "NaN" || filtercplPercentage(simulation) === "Infinity"
                            ? 0
                            : filtercplPercentage(simulation)
                        }}%)
                      </div>
                    </div>
                  </div>
                  <div class="investment-total2">
                    <img
                      v-if="filtercpl(simulation.plan) < simulation.cpl"
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse-danger.svg"
                      alt="ellipse-icon"
                    />
                    <img
                      v-else
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse-sucess.svg"
                      alt="ellipse-icon"
                    />
                    <h2 class="investment-value">
                      R$ {{ simulation.cpl | formatMoney }}
                    </h2>
                    <img
                      v-if="filtercpl(simulation.plan) < simulation.cpl"
                      class="ellipse"
                      src="@/assets/icons/simulator/arrow-danger.svg"
                      alt="ellipse-icon"
                    />
                    <img
                      v-else
                      class="ellipse"
                      src="@/assets/icons/simulator/arrow-sucess.svg"
                      alt="ellipse-icon"
                    />
                  </div>
                </b-col>
                <!-- TOTAL DE VENDAS -->
                <b-col cols="2" class="container-investment-plan">
                  <div class="investment-total">
                    <img
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse.svg"
                      alt="ellipse-icon"
                    />
                    <div class="investment-value">
                      <div class="investment-value">
                        R$ {{ filterTotalProfit(simulation.plan) | formatMoney }}
                      </div>
                      <div style="font-weight: 300; font-size: 11px;">
                        ({{
                          filterTotalProfitPercentage(simulation) === "NaN"
                            ? 0
                            : filterTotalProfitPercentage(simulation)
                        }}%)
                      </div>
                    </div>
                  </div>
                  <div class="investment-total2">
                    <img
                      v-if="
                        filterTotalProfit(simulation.plan) > simulation.profit
                      "
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse-danger.svg"
                      alt="ellipse-icon"
                    />
                    <img
                      v-else
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse-sucess.svg"
                      alt="ellipse-icon"
                    />
                    <h2 class="investment-value">
                      R$ {{ simulation.profit | formatMoney }}
                    </h2>
                    <img
                      v-if="
                        filterTotalProfit(simulation.plan) > simulation.profit
                      "
                      class="ellipse"
                      src="@/assets/icons/simulator/arrow-danger.svg"
                      alt="ellipse-icon"
                    />
                    <img
                      v-else
                      class="ellipse"
                      src="@/assets/icons/simulator/arrow-sucess.svg"
                      alt="ellipse-icon"
                    />
                  </div>
                </b-col>
                <!-- CPV -->
                <b-col
                  class="container-investment-plan"
                >
                  <div class="investment-total">
                    <img
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse.svg"
                      alt="ellipse-icon"
                    />
                    <div class="investment-value">
                      <div class="investment-value">
                        R$ {{ filtercpv(simulation.plan) | formatMoney }}
                      </div>
                      <div style="font-weight: 300; font-size: 11px;">
                        ({{
                          filtercpvPercentage(simulation) === "NaN"
                            ? 0
                            : filtercpvPercentage(simulation)
                        }}%)
                      </div>
                    </div>
                  </div>
                  <div class="investment-total2">
                    <img
                      v-if="filtercpv(simulation.plan) < simulation.cpv"
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse-danger.svg"
                      alt="ellipse-icon"
                    />
                    <img
                      v-else
                      class="ellipse"
                      src="@/assets/icons/simulator/ellipse-sucess.svg"
                      alt="ellipse-icon"
                    />
                    <h2 class="investment-value">
                      R$ {{ simulation.cpv | formatMoney }}
                    </h2>
                    <img
                      v-if="filtercpv(simulation.plan) < simulation.cpv"
                      class="ellipse"
                      src="@/assets/icons/simulator/arrow-danger.svg"
                      alt="ellipse-icon"
                    />
                    <img
                      v-else
                      class="ellipse"
                      src="@/assets/icons/simulator/arrow-sucess.svg"
                      alt="ellipse-icon"
                    />
                  </div>
                </b-col>
                <!-- Leads -->
                <b-col class="id" cols="1" style="color: var(--gray05)">
                  {{ simulation.leads }}
                </b-col>

                <!-- STATUS -->
                <!-- <b-col class="id" cols="1" style="color: var(--gray05)">
                  {{ filterStatus(simulation.plan) }}
                </b-col> -->
                <!--ACAO -->
                <b-col cols="1" class="d-flex" v-if="status">
                  <b-dropdown id="dropForm" right>
                    <template #button-content>
                      <img src="@/assets/img/icons/dots.svg" alt="dots" />
                    </template>
                    <b-dropdown-item @click="viewSimulation(simulation.plan)"
                      >Visualizar</b-dropdown-item
                    >
                    <b-dropdown-item @click="validTypeEdit(simulation)"
                      >Editar</b-dropdown-item
                    >
                    <b-dropdown-item @click="inactive(simulation.plan.id)"
                      >Desativar</b-dropdown-item
                    >
                  </b-dropdown>
                </b-col>
                <b-col cols="1" class="d-flex" v-else>
                  <b-dropdown id="dropForm" right>
                    <template #button-content>
                      <img src="@/assets/img/icons/dots.svg" alt="dots" />
                    </template>
                    <b-dropdown-item @click="viewSimulation(simulation.plan)"
                      >Visualizar</b-dropdown-item
                    >
                    <b-dropdown-item @click="active(simulation.plan.id)"
                      >Restaurar</b-dropdown-item
                    >
                    <b-dropdown-item @click="deleteHard(simulation.plan.id)"
                      >Deletar</b-dropdown-item
                    >
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
          </div>
        </template>
        <div v-else>
          <div v-for="(item, index) in 10" :key="index">
            <div
              class="d-flex align-items-center justify-content-around my-3 mt-4"
            >
              <b-col cols="2">
                <b-skeleton
                  height="10px"
                  width="70px"
                  class="ml-3"
                ></b-skeleton>
              </b-col>

              <b-col
                cols="2"
                style="display: flex; align-items: center; margin-left: -12px"
              >
                <b-skeleton height="30px" width="8px" class="ml-3"></b-skeleton>
                <div style="margin-left: -5px">
                  <b-skeleton
                    height="10px"
                    width="120px"
                    class="ml-3"
                  ></b-skeleton>
                  <b-skeleton
                    height="10px"
                    width="70px"
                    class="ml-3 mt-2"
                  ></b-skeleton>
                </div>
              </b-col>

              <b-col
                cols="2"
                style="display: flex; align-items: center; margin-left: -12px"
              >
                <b-skeleton height="30px" width="8px" class="ml-3"></b-skeleton>
                <div style="margin-left: -5px">
                  <b-skeleton
                    height="10px"
                    width="120px"
                    class="ml-3"
                  ></b-skeleton>
                  <b-skeleton
                    height="10px"
                    width="70px"
                    class="ml-3 mt-2"
                  ></b-skeleton>
                </div>
              </b-col>

              <b-col cols="2" style="display: flex; align-items: center">
                <b-skeleton height="30px" width="8px" class="ml-3"></b-skeleton>
                <div style="margin-left: -5px">
                  <b-skeleton
                    height="10px"
                    width="120px"
                    class="ml-3"
                  ></b-skeleton>
                  <b-skeleton
                    height="10px"
                    width="70px"
                    class="ml-3 mt-2"
                  ></b-skeleton>
                </div>
              </b-col>

              <b-col
                cols="2"
                style="display: flex; align-items: center; margin-left: -12px"
              >
                <b-skeleton height="30px" width="8px" class="ml-3"></b-skeleton>
                <div style="margin-left: -5px">
                  <b-skeleton
                    height="10px"
                    width="120px"
                    class="ml-3"
                  ></b-skeleton>
                  <b-skeleton
                    height="10px"
                    width="70px"
                    class="ml-3 mt-2"
                  ></b-skeleton>
                </div>
              </b-col>

              <b-col cols="1">
                <b-skeleton height="10px" width="70px"></b-skeleton>
              </b-col>

              <b-col cols="1">
                <b-skeleton height="10px" width="40px"></b-skeleton>
              </b-col>
            </div>
          </div>
        </div>
        <b-row>
          <b-col>
            <Paginate
              v-if="all_simulator.length > 0"
              :totalPages="pagination.totalPages"
              :activePage="pagination.currentPage"
              @to-page="toPage"
              @per-page="perPage"
              :disabled="true"
            />
          </b-col>
        </b-row>
      </div>

      <div v-if="all_simulator && isMobile" class="zoom">
        <b-row
          class="Table-header mb-1"
          style="
            padding: 15px 5px !important;
            gap: 0 !important;
            justify-content: space-between;
          "
          v-if="isMobile"
        >
          <b-col style="text-align: start" cols="4"> Plano </b-col>
          <b-col cols="2" style="margin-left: -20px"> Investimento </b-col>
          <b-col cols="5" style="text-align: end; margin-right: 20px">
            Total de vendas
          </b-col>
        </b-row>

        <template v-if="!loading">
          <div
            v-for="(simulation, index) in all_simulator"
            :key="index"
            class="Table-body-container"
          >
            <!-- BODY -->
            <template>
              <b-row
                class="Table-body"
                style="
                  padding: 13px 5px !important;
                  gap: 0 !important;
                  justify-content: space-between;
                "
                id="border-bottom"
              >
                <!--NOME DO PLANO -->
                <b-col
                  cols="4"
                  style="word-break: break-all"
                  v-if="simulation.plan.title.length < 20"
                  class="name-plan"
                >
                  {{ simulation.plan.title }}<br />
                  <span style="font-size: smaller; color: var(--white-dark)"
                    >Início:
                    {{ simulation.plan.start_date.substr(0, 10) | date }}</span
                  ><br />
                  <span style="font-size: smaller; color: var(--white-dark)"
                    >Final:
                    {{ simulation.plan.end_date.substr(0, 10) | date }}</span
                  >
                </b-col>

                <!-- INVESTIMENTO -->
                <b-col cols="4" class="container-investment-plan">
                  <div class="investment-total-mobile">
                    <div class="investment-value">
                      R$ {{ filterInvestment(simulation.plan) | formatMoney }}
                    </div>
                    <!-- <span class="investment-percentage">(+50%)</span> -->
                  </div>
                </b-col>

                <!-- TOTAL DE VENDAS -->
                <b-col
                  cols="3"
                  class="container-investment-plan"
                  style="display: flex; justify-content: end"
                >
                  <div class="investment-total-mobile">
                    <div class="investment-value">
                      R$ {{ filterTotalProfit(simulation.plan) | formatMoney }}
                    </div>
                    <!-- <span class="investment-percentage">(+50%)</span> -->
                  </div>
                </b-col>
                <b-col cols="1">
                  <div class="Table-body-action">
                    <div @click="viewSimulation(simulation.plan)">
                      <img src="@/assets/img/icons/eye.svg" />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </template>
          </div>
        </template>
        <b-row>
          <b-col>
            <Paginate
              v-if="all_simulator.length > 0"
              :totalPages="pagination.totalPages"
              :activePage="pagination.currentPage"
              @to-page="toPage"
              @per-page="perPage"
              :disabled="true"
            />
          </b-col>
        </b-row>
      </div>
      <!-- SIMULAÇÔES NÃO ENCONTRADO-->
      <b-row
        v-if="
          !loading &&
          all_simulator &&
          all_simulator.length === 0 &&
          search.length > 0
        "
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma simulação foi encontrada</p>
      </b-row>

      <!-- SE NÃO EXISTIR SIMULAÇÔES -->
      <b-row
        v-if="!loading && all_simulator.length === 0 && search.length === 0"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma simulação criada</p>
      </b-row>
      <ModalViewSimulator :selected_simulation="selected_simulation" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Money } from "v-money";
import _ from "lodash";
//
import Paginate from "@/components/Paginate";
import BaseView from "@/template/BaseView.vue";
import ModalViewSimulator from "@/components/Simulador/ModalViewSimulator";
//
import PlanService from "@/services/resources/PlanService";
const servicePlan = PlanService.build();
import TrafficService from "@/services/resources/TrafficService";
const serviceTraffic = TrafficService.build();

import CampaignService from "@/services/resources/CampaignService";
const serviceCampaign = CampaignService.build();
//

import ModalCreateTraffic from "@/components/Simulador/ModalCreateTraffic";
import moment from "moment-timezone";

export default {
  components: {
    Paginate,
    BaseView,
    Money,
    ModalViewSimulator,
    ModalCreateTraffic
  },
  data() {
    return {
      navigation: [
        { link: "Estratégico", to: "/dynamicRoute/estrategico" },
        { link: "Gestão de planos", to: this.$route.fullPath },
      ],
      selected_simulation: null,
      all_simulator: [],
      search: "",
      total: null,
      status: true,
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  filters: {
    formatMoney(number) {
      if (typeof number !== "undefined" && number && number != 0) {
        number = parseFloat(number);
        const formato = {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
        };
        return number.toLocaleString("pt-BR", formato);
      }
      return "0,00";
    },
    formatNumber(number) {
      if (typeof number !== "undefined" && number) {
        return number.toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
          useGrouping: true,
        });
      }
      return "0";
    },
  },
  methods: {
    filtercpvPercentage(simulation) {
      return (simulation.cpv * 100) / this.filtercpv(simulation.plan) < 1
        ? ((simulation.cpv * 100) / this.filtercpv(simulation.plan)).toFixed(2)
        : ((simulation.cpv * 100) / this.filtercpv(simulation.plan)).toFixed(0);
    },
    filterTotalProfitPercentage(simulation) {
      return (simulation.profit * 100) /
        this.filterTotalProfit(simulation.plan) <
        1
        ? (
            (simulation.profit * 100) /
            this.filterTotalProfit(simulation.plan)
          ).toFixed(2)
        : (
            (simulation.profit * 100) /
            this.filterTotalProfit(simulation.plan)
          ).toFixed(0);
    },
    filtercplPercentage(simulation) {
      return (simulation.cpl * 100) / this.filtercpl(simulation.plan) < 1
        ? ((simulation.cpl * 100) / this.filtercpl(simulation.plan)).toFixed(2)
        : ((simulation.cpl * 100) / this.filtercpl(simulation.plan)).toFixed(0);
    },
    filterInvestmentPercetentage(simulation) {
      return (simulation.investing * 100) /
        this.filterInvestment(simulation.plan) <
        1
        ? (
            (simulation.investing * 100) /
            this.filterInvestment(simulation.plan)
          ).toFixed(2)
        : (
            (simulation.investing * 100) /
            this.filterInvestment(simulation.plan)
          ).toFixed(0);
    },
    validTypeEdit(simulation) {
      var cpc = simulation.plan.metas.find(
        (item) => item.meta_key == "cpc"
      ).meta_value;
      if (cpc && cpc == "v2") {
        this.$router.push({
          name: "PlanoEditar2",
          params: { id: simulation.plan.id },
        });
      } else {
        this.$router.push({
          name: "PlanoEditar",
          params: { id: simulation.plan.id },
        });
      }
    },
    typeValidSimulator() {
      this.$router.push({
        name: "Plano2",
      });
    },
    filterInvestment(item) {
      if (item) {
        var investimento = item.metas.find(
          (item) => item.meta_key == "investimento"
        ).meta_value;
        return investimento;
      } else {
        return 0;
      }
    },
    filterStatus(item) {
      const endDate = new Date(item.end_date);
      const now = new Date();
      if (endDate > now) {
        return "Ativo";
      } else {
        return "Inativo";
      }
    },

    filterTotalProfit(item) {
      if (item) {
        var totalProfit = item.metas.find(
          (item) => item.meta_key == "total_profit"
        );
        if (totalProfit) {
          return totalProfit.meta_value;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    filtercpl(item) {
      var cpc = item.metas.find((item) => item.meta_key == "cpc").meta_value;
      if (cpc && cpc == "v2") {
        if (item) {
          var cpl = item.metas.find(
            (item) => item.meta_key == "cpl"
          ).meta_value;
          return cpl;
        }
      } else {
        if (item) {
          var cpl = item.metas.find(
            (item) => item.meta_key == "cpl"
          ).meta_value;
          if (cpl) {
            return cpl;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
    },
    filtercpv(item) {
      var cpc = item.metas.find((item) => item.meta_key == "cpc").meta_value;
      if (cpc && cpc == "v2") {
        if (item) {
          var cpv = item.metas.find(
            (item) => item.meta_key == "cpv"
          ).meta_value;
          return cpv;
        } else {
          return 0;
        }
      } else {
        if (item) {
          var cpv = item.metas.find(
            (item) => item.meta_key == "cpv"
          ).meta_value;
          return cpv;
        } else {
          return 0;
        }
      }
    },
    viewSimulation(plan) {
      this.selected_simulation = plan;
      this.$bvModal.show("Modal-view-simulator");
    },
    deleteHard(id) {
      Vue.swal({
        title: "Planos",
        text: `Deseja realmente deletar a simulação?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: id,
          };
          servicePlan
            .destroy(data)
            .then(() => {
              this.loading = false;
              this.$bvToast.toast("Simulação deletada com sucesso", {
                title: "Planos",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.getSimulator();
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao deletar simulação", {
                title: "Planos",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    active(id) {
      Vue.swal({
        title: "Planos",
        text: `Deseja restaurar a simulação?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Restaurar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: id,
            status: "active",
          };
          servicePlan
            .update(data)
            .then(() => {
              this.$bvToast.toast("Simulação restaurada com sucesso", {
                title: "Planos",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.getPages();
            })
            .catch((error) => {
              // console.log(error)
              // this.$bvToast.toast("Erro ao restaurar simulação", {
              //   title: "Planos",
              //   variant: "danger",
              //   autoHideDelay: 5000,
              //   appendToast: true,
              // });
            })
            .finally(() => {
              this.getSimulator(this.search);
              this.loading = false;
            });
        }
      });
    },
    inactive(id) {
      Vue.swal({
        title: "Planos",
        text: `Deseja desativar a simulação?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Desativar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: id,
            status: "inactive",
          };
          servicePlan
            .update(data)
            .then(() => {
              this.$bvToast.toast("Simulação inativado com sucesso", {
                title: "Planos",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao deletar simulação", {
                title: "Planos",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.getSimulator(this.search);
              this.loading = false;
            });
        }
      });
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.getSimulator(this.search);
    }, 500),
    toPage(page) {
      this.pagination.currentPage = page;
      this.getSimulator(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.getSimulator(this.search);
    },
    changeStatusForm() {
      this.pagination.currentPage = 1;
      this.getSimulator(this.search);
    },
    getSimulator(search = null) {
      this.loading = true;
      this.pagination.totalPages = 1;

      servicePlan
        .read(
          `/all?search=${this.search}&status=${
            this.status == false ? "inactive" : "active"
          }`
        )
        .then((resp) => {
          // Simulações
          // console.log("res", resp);
          this.all_simulator = resp;
          this.total = resp.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    this.getSimulator();
    // this.getLeadsPlan();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.Table-header > div {
  padding: 0 !important;
}
.Ativo {
  content: "";
  position: relative;
  width: 10px;
  height: 10px;
  background: var(--greenn);
  border-radius: 50%;
}
.Inativo::before {
  content: "";
  position: relative;
  width: 10px;
  height: 10px;
  background: var(--red);
  border-radius: 50%;
}

.ellipse {
  left: -1px;
}
.investment-percentage {
  color: var(--gray05);
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  right: -50px;
}
.investment-total2 {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  margin-left: -5px;
  &::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 5px;
    background: #ccc;
    bottom: 20px;
    left: 8.5px;
  }
  .investment-value {
    color: var(--gray01);
    font-weight: 600;
    font-size: 11px;
    margin: 0;
  }
}

.investment-total-mobile {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  .investment-value {
    display: flex;
    color: var(--gray05);
    font-weight: 600;
    font-size: 12.5px;
    margin: 0;
    gap: 3px;
    justify-content: center;
  }
}
.investment-total {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 5px;
    background: #ccc;
    top: 19px;
    left: 3.5px;
  }
  .investment-value {
    display: flex;
    color: var(--gray05);
    font-weight: 600;
    font-size: 11px;
    margin: 0;
    gap: 3px;
    justify-content: center;
  }
}

.container-investment-plan {
  display: grid;
  gap: 10px;
}
.name-plan {
  color: var(--gray05);
  font-weight: 600;
}
.icon-type {
  width: 25px;
}
.Table-body-container .Table-body {
  background-color: #fafafb !important;
}
.Table-body-container:nth-child(2n-2) .Table-body {
  background-color: #ffffff !important;
}
.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 20px;
  top: 17px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.header-table {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  padding-top: 15px;
}
@media (max-width: 1620px) {
  .screenn {
    zoom: 0.9;
  }
}
@media (max-width: 1480px) {
  .screenn {
    zoom: 0.87;
  }
}
@media (max-width: 1000px) {
  .zoom {
    zoom: 0.9;
  }
}
@media screen and (max-width: 768px) {
  .Table-header {
    gap: 50px;
    text-align: center;
  }
  .Table-body {
    gap: 50px;
  }

  .container-pesquisa {
    text-align: left;
    padding-bottom: 10px;
  }
  .search {
    left: 20px;
  }
}
</style>
